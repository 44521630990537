import React from "react";
import "./Header.css";
import logo2 from "../../img/logo2.png";
import { NavLink, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Header = () => {
  const navigate = useNavigate();
  const caseconverter = (text) => {
    const fintext = text.toUpperCase();
    return fintext;
  };

  const navigateto1 = () => {
    navigate("/#feature");
  };

  const navigateto2 = () => {
    navigate("/#Community");
  };

  const navigateto3 = () => {
    navigate("/#Blog");
  };

  const navigateto4 = () => {
    navigate("/#aigym");
  };
  const navigateto5 = () => {
    navigate("/#contact");
  };

  return (
    <>
      <header className="header">
        <nav className="navbar navbar-expand-lg fixed-top" id="main-nav">
          <div className="container">
            <HashLink className="navbar-brand" to="/#home">
              <img className="white-logo" src={logo2} alt="" />
              <img className="color-logo" src={logo2} alt="" />
            </HashLink>
            <button
              className="navbar-toggler order-1"
              type="button"
              data-toggle="collapse"
              data-target="#main-nav-collapse"
              aria-controls="main-nav-collapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="menu-toggle">
                <span className="hamburger">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
                <span className="hamburger-cross">
                  <span></span>
                  <span></span>
                </span>
              </span>
            </button>
            <div
              className="collapse navbar-collapse order-3 order-lg-2"
              id="main-nav-collapse"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <HashLink
                    to="/#home"
                    className="nav-link"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {caseconverter("home")}
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink to="/#activities">
                    {caseconverter("Activities")}
                  </HashLink>
                  {/* <a className="nav-link nav-link-scroll" onClick={navigateto1} href="#feature"></a> */}
                </li>
                <li className="nav-item">
                  <HashLink to="/#Community">
                    {caseconverter("Our Community")}
                  </HashLink>
                  {/* <a className="nav-link nav-link-scroll" onClick={navigateto2} href="#Community">Our Community</a> */}
                </li>
                {/* <li className="nav-item">
                  <NavLink to="/bayeslabs/blogs">
                    {caseconverter("Blog")}
                  </NavLink>

                </li> */}

                <li className="nav-item">
                  <HashLink to="/#contact">{caseconverter("contact")}</HashLink>
                  {/* <a className="nav-link nav-link-scroll" onClick={navigateto5} href="#contact">contact</a> */}
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
