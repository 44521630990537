import React, { useEffect, useState } from 'react'
import './Allblogs.css'
import { collection, db, onSnapshot } from '../../firebaseConfig';
import { NavLink, useNavigate } from 'react-router-dom';
import Scrollto from '../../components/Scrollto/Scrollto';
import Htmlrendering from '../../utils/Htmlrendering/Htmlrendering';
import { Helmet } from 'react-helmet-async';
import Imgrendering from '../../utils/Imgrendering/Imgrendering';

const Allblogs = () => {
    const [loader, setLoader] = useState(true)
    const blogsdata = [1, 2, 3, 4, 5, 6];
    const [dataArray, setDataArray] = useState([]);
    const [dataCategories, setDataCategories] = useState([])
    const [searchvalue, setSearchvalue] = useState('')
    const [blognames, setBlogNames] = useState([])
    const [suggestions, setSuggestions] = useState([]);
    const [noResult, setNoResult] = useState(false)
    const navigate = useNavigate()

    const breadstyle = {
        position: "relative",
        zIndex: "1",
        padding: "135px 0px 125px",
        overflow: "hidden",
        background: "linear-gradient( 40deg, rgb(50,73,214) 0%, rgb(13,197,173) 100%)"
    }


    const moveToCategorie = (value) => {
        console.log(value)
        navigate(`/bayeslabs/${value}/blog`)
    }


    // search field controllers
    const handleSearch = (e) => {
        setNoResult(false)
        console.log(e.target.value)
        const text = e.target.value;
        setSearchvalue(e.target.value)

        const matchingSuggestions = dataArray
            .filter(obj => obj.title.toLowerCase().includes(text.toLowerCase()))
            .map(obj => obj.title);

        setSuggestions(matchingSuggestions);
    }

    const findValue = () => {
        console.log(searchvalue)
        const foundObject = dataArray.find(obj => obj.title.toLowerCase().includes(searchvalue))
        if (foundObject) {
            console.log('Found:', foundObject);
            navigate(`/bayeslabs/${searchvalue}/blogs`)

            // navigate(`/blog/${foundObject.id}/blog-post`)

        } else {
            setNoResult(true)
            setTimeout(() => {
                setNoResult(false)
            }, 3000);

        }
    }


    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, 'bayeslabs-posts'), (querySnapshot) => {
            const newDataArray = [];
            querySnapshot.forEach((doc) => {
                if (doc.data().published == true) {
                newDataArray.push({ id: doc.id, ...doc.data() });
                }
            });
            console.log(newDataArray)
            setDataArray(newDataArray);
            setTimeout(() => {
                setLoader(false)
            }, 2000);

        });


        const unsubscribe2 = onSnapshot(collection(db, 'bayeslabs-categories'), (querySnapshot) => {
            const newDataArray2 = [];
            querySnapshot.forEach((doc) => {
                newDataArray2.push({ id: doc.id, ...doc.data() });
            });
            console.log(newDataArray2)
            setDataCategories(newDataArray2);
            setTimeout(() => {
                setLoader(false)
            }, 2000);
        });

        return () => {
        }
    }, [])

    return (
        <>
            <Helmet>
                <meta charset="UTF-8" />
                <meta name="keywords" content="ai drug discoverty,ai powered innovations in chemical manufacturing, manufacturing" />
            </Helmet>
            {loader && <div className="loader"></div>}
            <Scrollto />
            <div className="body_wrapper">
                <section className="blog_area sec_pad">
                    <div className="container">
                        <div className='row g-0'>
                            <div className='col-lg-9 col-md-12 col-sm-12'>
                                {
                                    dataArray.length == 0 ?
                                        <span style={{ color: 'blue', fontSize: '30px' }}> More blogs comming soon...</span>
                                        :
                                        <div className="row" id="blog_masonry">
                                            {
                                                dataArray.slice(0, 4).map((data, i) => {
                                                    return <>
                                                        <div className="col-lg-4 col-sm-6" onClick={() => navigate(`/post/${data.id}`)}>
                                                            <div className="blog_grid_item mb-30">
                                                                <div className="blog_content">
                                                                    <div className="blog_img">
                                                                        <Imgrendering
                                                                            htmlString={data.content}
                                                                        />
                                                                    </div>
                                                                    <NavLink to={`/posts${data.id}`}>
                                                                        <h2 className="f_p f_size_20 f_500 t_color mb_20 len_text">{data.title}</h2>
                                                                    </NavLink>
                                                                    <div className="entry_post_info">
                                                                        By: <NavLink to={`/post/${data.id}`}>{data.author}</NavLink>
                                                                        <NavLink to={`/post/${data.id}`}>{data.created_date.toDate().toString().slice(4, 15)}</NavLink>
                                                                    </div>
                                                                    <div className='blog-description'>
                                                                        <Htmlrendering content={data.content} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                )
                                            }
                                        </div>
                                }
                            </div>
                            <div className='col-lg-3 col-md-12 col-sm-12'>
                                {
                                    dataArray.length > 0 &&
                                    <div className='right_card p-2'>
                                        <span style={{ display: 'inline-block', fontSize: '20px', color: 'blue' }} className='m-2'>Recommended reads</span>
                                        {
                                            dataArray.map((data) => {
                                                return <div className="card mb-2 rec_card" onClick={() => navigate(`/post/${data.id}`)}>
                                                    <div className="card-body">
                                                        <div className='row gx-0'>
                                                            <div className="col-md-4">
                                                                <div className="blog_img2">
                                                                    <Imgrendering
                                                                        htmlString={data.content}
                                                                        classname="imager"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <h5 className="len_text">{data.title}</h5>
                                                                <span style={{ fontSize: '12px', color: '#99a1af' }}>By : {data.author} / {data.created_date.toDate().toString().slice(4, 15)}</span>
                                                                <div className='blog-description2' >
                                                                    <Htmlrendering content={data.content} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Allblogs