import { initializeApp } from "firebase/app";
import {
    createUserWithEmailAndPassword,
    updateProfile,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    getAuth,
    sendEmailVerification,
    signOut
} from 'firebase/auth';
import {
    getFirestore,
    collection,
    addDoc,
    setDoc,
    deleteDoc,
    doc,
    query,
    where,
    onSnapshot,
    orderBy,
    serverTimestamp,
    updateDoc,
    arrayUnion,
    getDocs,
    getDoc,
} from "firebase/firestore";


import {
    getStorage,
    getMetadata,
    getDownloadURL,
    ref,
    uploadBytes,
    uploadString
} from "firebase/storage";


const firebaseConfig = {
    apiKey: "AIzaSyCN4aozpjya1-IPcXW60t1NxLu7bUUfmVU",
    authDomain: "boltzmann-admin.firebaseapp.com",
    projectId: "boltzmann-admin",
    storageBucket: "boltzmann-admin.appspot.com",
    messagingSenderId: "1023878106371",
    appId: "1:1023878106371:web:bbe53083ddf3a1d1c1ad36"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);



export {
    auth,
    createUserWithEmailAndPassword,
    updateProfile,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signOut,
    sendEmailVerification,
}

export {
    addDoc,
    collection,
    setDoc,
    db,
    doc,
    query,
    where,
    onSnapshot,
    orderBy,
    serverTimestamp,
    deleteDoc,
    updateDoc,
    arrayUnion,
    getDocs,
    getDoc,
    
}

export {
    storage,
    getMetadata,
    getDownloadURL,
    ref,
    uploadBytes,
    uploadString
}



