import React, { useEffect, useState } from 'react'
import './css/style.css'
// import './css/responsive.css'
import './Blogs.css'
import { NavLink, useNavigate } from 'react-router-dom'
import { collection, onSnapshot } from 'firebase/firestore'
import { db } from '../../firebaseConfig'
import Htmlrendering from '../../utils/Htmlrendering/Htmlrendering'
import Imgrendering from '../../utils/Imgrendering/Imgrendering'

const Blogs = () => {
  const [dataArray, setDataArray] = useState([]);
  const navigate = useNavigate()

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'bayeslabs-posts'), (querySnapshot) => {
      const newDataArray = [];
      querySnapshot.forEach((doc) => {
        if (doc.data().published == true) {
          newDataArray.push({ id: doc.id, ...doc.data() });
        }
      });
      console.log(newDataArray)
      setDataArray(newDataArray);
    });

    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <>
      <section className="blog_area sec_pad">
        <div className="container">
          <div className="s-title">
            <h2 className="wow">Blogs</h2>
          </div>
          <div className="row" id="blog_masonry">
            {
              dataArray.slice(0, 3).map((data, i) => {
                return <>
                  <div id="Blog" className="col-lg-4 col-sm-6" onClick={() => navigate(`/post/${data.id}`)}>
                    <div className="blog_grid_item mb-30">
                      <div className="blog_content">
                        <div class="blog_img">
                          <Imgrendering
                            htmlString={data.content}
                          />
                        </div>
                        <NavLink to={`post/${data.id}`}>
                          <h5 className="f_p f_size_20 f_500 t_color mb_20 len_text">{data.title}</h5>
                        </NavLink>
                        <div className='blog-description'>
                          <Htmlrendering content={data.content} />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              })
            }
          </div>
          <NavLink className="btn btn2 store-btn wow slideInUpBig" to="/bayeslabs/blogs" data-wow-duration=".65s" data-wow-delay=".6s">
            <span>More blogs</span> <i class="icon-arrow-right"></i>
          </NavLink>
        </div>
      </section>
    </>
  )
}

export default Blogs