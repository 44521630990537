import React, { useEffect, useState } from 'react'
import "./BlogPosts.css"
import { db, doc } from '../../firebaseConfig'
import { documentId, getDoc } from 'firebase/firestore'
import { useParams } from 'react-router-dom'
import Htmlrendering from '../../utils/Htmlrendering/Htmlrendering'
import Scrollto from '../../components/Scrollto/Scrollto'
import { onSnapshot, collection } from '../../firebaseConfig'
import { NavLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async'
import Imgrendering from '../../utils/Imgrendering/Imgrendering'
import Detailrendering from '../../utils/Detailrendering/Detailrendering'

const breadstyle = {
    position: "relative",
    zIndex: "1",
    padding: "235px 0px 125px",
    overflow: "hidden",
    background: "linear-gradient( 40deg, rgb(50,73,214) 0%, rgb(13,197,173) 100%)"
}

const BlogPosts = () => {
    const [loader, setLoader] = useState(true)
    const [blogdata, setBlogdata] = useState({})
    const [dataArray, setDataArray] = useState([]);
    const navigate = useNavigate()
    const { id } = useParams()

    const getBlog = async () => {
        try {
            setLoader(true)
            const docRef = doc(db, "bayeslabs-posts", id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                console.log('Document data:', docSnap.data());
                setBlogdata(docSnap.data())
                setTimeout(() => {
                    setLoader(false)
                }, 2000);
            } else {
                console.log('No such document!');
                setLoader(false)
            }
        } catch (error) {
            console.error('Error getting document:', error);
        }
    }

    const getRelatedPosts = () => {
        const unsubscribe = onSnapshot(collection(db, 'bayeslabs-posts'), (querySnapshot) => {
            const newDataArray = [];
            querySnapshot.forEach((doc) => {
                if(doc.data().published==true){
                newDataArray.push({ id: doc.id, ...doc.data() });
                }
            });
            console.log(newDataArray)
            setDataArray(newDataArray);
        });

        setTimeout(() => {
            setLoader(false)
        }, 2000);
    }

    useEffect(() => {
        getBlog()
        getRelatedPosts()
        window.scrollTo(0, 0)
        return () => {
        }
    }, [id])

    return (
        <>
            <Helmet>
                {blogdata.title && <title>Bayeslabs | {blogdata.title}</title>}
                <meta name="description" content={blogdata.content} />
            </Helmet>
            <Scrollto />
            {loader && <div className="loader"></div>}

            <section className="blog_area sec_pad" id="blog-section-pad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 blog_sidebar_left">
                            <div className="blog_single mb_50">
                                <img className="img-fluid" alt="" />
                                <div className="blog_content">
                                    <div style={{ padding: '25px', backgroundColor: 'white' }}>
                                        <h5 className="f_p f_size_20 f_500 t_color mb-30">{blogdata.title}</h5>
                                        <div class="entry_post_info">
                                            By: <NavLink to={`/blog/${blogdata.id}/blog-post`}>{blogdata.author}</NavLink>
                                            {blogdata.created_date && <NavLink to={`/blog/${blogdata.id}/blog-post`}>{blogdata.created_date.toDate().toString().slice(4, 15)}</NavLink>}
                                        </div>
                                        <Detailrendering
                                            content={blogdata.content}
                                            type="detailed"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="blog_post">
                                <div className="widget_title">
                                    <h3 className="f_p f_size_20 t_color3">Related Post</h3>
                                    <div className="border_bottom"></div>
                                </div>
                                <div className="row">
                                    <div className="row g-2">
                                        {
                                            dataArray.slice(0, 3).map((data) =>
                                                <>
                                                    <div className="col-lg-4 col-sm-6" onClick={() => navigate(`/post/${data.id}`)}>
                                                        <div className="blog_post_item">
                                                            <div className="blog_content">
                                                                <div class="blog_img">
                                                                    <Imgrendering
                                                                        htmlString={data.content}
                                                                    />
                                                                </div>
                                                                <NavLink to={`/post/${data.id}`}>
                                                                    <h5 className="f_p f_size_16 f_500 t_color len_text">{data.title}</h5>
                                                                </NavLink>
                                                                <div className='blog-description'>
                                                                    <Htmlrendering content={data.content} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BlogPosts