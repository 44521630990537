import React, { useEffect } from 'react'

const Scrollto = () => {

    useEffect(() => {
      
        window.scrollTo(0, 0);

      return () => {
      }
    }, [])
    
  return null
}

export default Scrollto