import {
	createBrowserRouter,
	RouterProvider,
	Route,
	Link,
	Routes,
} from "react-router-dom";

import { Suspense, lazy, useEffect } from "react";

import Home from "./pages/Home/Home";
import BlogPosts from "./pages/BlogPosts/BlogPosts";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Allblogs from "./pages/Allblogs/Allblogs";
import CategorieBlogs from "./pages/CategorieBlogs/CategorieBlogs";
import Scrollto from "./components/Scrollto/Scrollto";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import SearchResults from "./pages/SearchResults/SearchResults";
import BlogsRoot from "./pages/BlogsRoot/BlogsRoot";
import { HelmetProvider } from "react-helmet-async";

function App() {

	useEffect(() => {
		return () => {
		}
	}, [])

	return (
		<>
			<HelmetProvider>
				<Scrollto />
				<Header />
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/home" element={<Home />} />
					<Route path="/post/:id" element={<BlogPosts />} />
					<Route path="/bayeslabs/blogs" element={<BlogsRoot />}>
						<Route path="" element={<Allblogs />} />
						<Route path=":search" element={<SearchResults />} />
					</Route>
					<Route path="/bayeslabs/:categorie/blog" element={<CategorieBlogs />} />
					{/* <Route path="/bayeslabs/:search/blogs" element={<BlogsRoot />} >
					<Route path="" element={<Allblogs/>}/>
					<Route path=":search" element={<SearchResults/>}/>
				</Route> */}
					<Route path="*" element={<PageNotFound />} />
				</Routes>
				<Footer />
			</HelmetProvider>
		</>
	);
}

export default App;
