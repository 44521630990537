import React from "react";
import "./Community.css";

const Community = () => {
  return (
    <>
      <section className="bg-img bg-3 s-padding h4-get-the-app" id="Community">
        <div className="overlay"></div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="get-the-app-content">
                <h2>Join Us</h2>
                <p>
                  {" "}
                  Whether you're a seasoned AI ninja or a curious newcomer,
                  there's a spot for you in our tribe. Join BayesLabs Open
                  Research Community for AI and let's rewrite the future of AI
                  together.
                </p>
                <div className="btn-wrapper ovh">
                  <a
                    className="btn store-btn fill-style wow slideInUpBig"
                    data-wow-duration=".65s"
                    data-wow-delay=".4s"
                    href="https://discord.gg/fM3vHqbMUp"
                    target="_blank"
                  >
                    <i className="icon_group"></i> <span> Join Us</span>
                  </a>
                  <a
                    className="btn store-btn wow slideInUpBig"
                    data-wow-duration=".65s"
                    data-wow-delay=".6s"
                    href="https://mail.google.com/mail/?view=cm&fs=1&to=contact@bayeslabs.co&su=bayeslabs ping&body=Plz write your message"
                  >
                    <i className="icon_mail_alt"></i>
                    <span> Ping Us</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="get-the-app-content">
                <h2>Stay Connected</h2>
                <p>
                  {" "}
                  Keep the AI party going! Follow us on social media and
                  subscribe to our newsletter for the latest AI buzz, community
                  updates, and exclusive goodies.
                </p>
                <br></br>
                <br></br>
                <ul className="social-profile style-wb">
                  <li>
                    <a href="https://www.instagram.com/bayeslabs.co/">
                      <i className="social_instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/BayesLabs">
                      <i className="social_twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/bayeslabs">
                      <i className="social_linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Community;
