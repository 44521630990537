import React from "react";

const Whatwedo = () => {
  return (
    <>
      <section
        className="h4-awesome-feature bg-color s-padding"
        id="activities"
      >
        <div className="container">
          <div className="s-title">
            <h2 className="wow">Activities</h2>
            {/* <p>
              Prioritize customer satisfaction with adaptive chatbot solutions
              that understand and respond to individual needs
              <br />
              Our platform is designed to cater to the unique requirements of
              enterprises, providing solutions that go beyond one-size-fits-all
              approaches.
            </p> */}
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <div className="icon-box awf-item wow slideInUp">
                {/* <div className="icon-box-icon">
                  <i className="icon_lock_alt"></i>
                </div> */}
                <div className="icon-box-details">
                  <h3 className="icon-box-title">Open Access Resources</h3>
                  <p>
                    Dive into a treasure trove of AI research papers, datasets,
                    tools, and tutorials handpicked by top-notch experts.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="icon-box awf-item wow slideInUp">
                {/* <div className="icon-box-icon">
                  <i className="icon_cloud-upload_alt"></i>
                </div> */}
                <div className="icon-box-details">
                  <h3 className="icon-box-title">Community Forums</h3>
                  <p>
                    Jump into our buzzing forums, where debates sizzle, ideas
                    flow, and friendships are forged. Connect, learn, and grow
                    with our vibrant community.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <div className="icon-box awf-item wow slideInUp">
                {/* <div className="icon-box-icon">
                  <i className="icon_lock_alt"></i>
                </div> */}
                <div className="icon-box-details">
                  <h3 className="icon-box-title">Project Collaboration</h3>
                  <p>
                    Ready to turn your AI dreams into reality? Find your tribe
                    here. Collaborate on projects, share insights, and level up
                    together
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="icon-box awf-item wow slideInUp">
                {/* <div className="icon-box-icon">
                  <i className="icon_cloud-upload_alt"></i>
                </div> */}
                <div className="icon-box-details">
                  <h3 className="icon-box-title">Events and Workshops</h3>
                  <p>
                    From mind-bending workshops to thought-provoking webinars,
                    our events lineup is a playground for curious minds. Get
                    ready to expand your AI horizons
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <div className="icon-box awf-item wow slideInUp">
                {/* <div className="icon-box-icon">
                  <i className="icon_lock_alt"></i>
                </div> */}
                <div className="icon-box-details">
                  <h3 className="icon-box-title">Networking Opportunities</h3>
                  <p>
                    Rub shoulders with AI rockstars, connect with industry pros,
                    and build lasting relationships with like-minded individuals
                    from around the globe.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="icon-box awf-item wow slideInUp">
                {/* <div className="icon-box-icon">
                  <i className="icon_cloud-upload_alt"></i>
                </div> */}
                <div className="icon-box-details">
                  <h3 className="icon-box-title">Publication Opportunities</h3>
                  <p>
                    Got a brilliant idea or groundbreaking research? Share it
                    with the world through our platform and make your mark on
                    the AI landscape
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <div className="icon-box awf-item wow slideInUp">
                {/* <div className="icon-box-icon">
                  <i className="icon_lock_alt"></i>
                </div> */}
                <div className="icon-box-details">
                  <h3 className="icon-box-title">
                    Podcasts and Panel Discussions
                  </h3>
                  <p>
                    Tune in to our riveting podcasts and gripping panel
                    discussions—where AI luminaries dissect the latest trends,
                    debate the burning questions, and inspire the next
                    generation of innovators.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="icon-box awf-item wow slideInUp">
                {/* <div className="icon-box-icon">
                  <i className="icon_cloud-upload_alt"></i>
                </div> */}
                <div className="icon-box-details">
                  <h3 className="icon-box-title">Hackathons</h3>
                  <p>
                    Ready to roll up your sleeves and get your hands dirty? Join
                    our electrifying hackathons, where you'll collaborate with
                    fellow AI enthusiasts to tackle real-world challenges and
                    emerge victorious.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Whatwedo;
