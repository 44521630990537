import React from "react";

const Aigym = () => {
  return (
    <>
      <section className="h4-awesome-feature bg-color s-padding" id="aigym">
        <div className="overlay"></div>
        <div className="container">
          <div className="row align-items-center">
            <div className="s-title">
              <div className="get-the-app-content">
                <h2>Mission</h2>
                <p>
                  {" "}
                  We're on a mission to democratize AI knowledge, <br />
                  fuel innovation, and spark collaborations that make a
                  real-world impact.
                  <br /> Our goal? To empower YOU to shape the future of AI.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Aigym;
