import React, { useEffect, useState } from "react";
import "./Home.css";
import Hero from "../../components/Hero/Hero";
import Whatwedo from "../../components/whatwedo/Whatwedo";
import Blogs from "../../components/blogs/Blogs";
import Aigym from "../../components/aigym/Aigym";
import Contact from "../../components/contatinfo/Contact";
import Community from "../../components/community/Community";
import { Helmet } from "react-helmet-async";
import { collection, db, onSnapshot } from "../../firebaseConfig";

const Home = () => {
  const [dataArray, setDataArray] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "bayeslabs-posts"),
      (querySnapshot) => {
        const newDataArray = [];
        querySnapshot.forEach((doc) => {
          if (doc.data().published == true) {
            newDataArray.push({ id: doc.id, ...doc.data() });
          }
        });
        console.log(newDataArray);
        setDataArray(newDataArray);
      }
    );
    const timer = setTimeout(() => {
      setLoader(false);
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      <Helmet>
        {/* <link rel="icon" type="image/png" href={fav} /> */}
        <title>Bayeslabs</title>
        <meta
          name="description"
          content={`A vibrant community transforming drug discovery
                    `}
        />
      </Helmet>
      {loader && <div className="loader"></div>}
      <Hero />
      <Whatwedo />
      <Aigym />
      {dataArray.length > 0 && <Blogs />}

      <Community />
      <Contact />
    </>
  );
};

export default Home;
