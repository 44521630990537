import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { collection, db, onSnapshot } from '../../firebaseConfig';
import { NavLink } from 'react-router-dom';
import Htmlrendering from '../../utils/Htmlrendering/Htmlrendering';
import Imgrendering from '../../utils/Imgrendering/Imgrendering';

const breadstyle = {
  position: "relative",
  zIndex: "1",
  paddingTop: '60px',
  paddingBottom: '60px',
  marginTop: "60px",
  overflow: "hidden",
  background: "linear-gradient( 40deg, rgb(50,73,214) 0%, rgb(13,197,173) 100%)"
}

const SearchResults = () => {
  const [dataArray, setDataArray] = useState([]);
  const [loader, setLoader] = useState(true)
  const navigate = useNavigate()
  const { search } = useParams()



  useEffect(() => {

    const unsubscribe = onSnapshot(collection(db, 'bayeslabs-posts'), (querySnapshot) => {
      setLoader(true)
      const newDataArray = [];
      querySnapshot.forEach((doc) => {
        if (doc.data().published == true) {
          newDataArray.push({ id: doc.id, ...doc.data() });
        }
      });
      const foundObject = newDataArray.filter(obj => obj.title.toLowerCase().includes(search))
      setDataArray(foundObject);
      setTimeout(() => {
        setLoader(false)
      }, 2000);
    });

    return () => {
    }
  }, [search])


  return (
    <>
      <div className="body_wrapper">
        <section className="blog_area sec_pad">
          <div className="container">
            <h4 style={{ color: 'rgb(50,73,214)' }}> results for : <span style={{ color: "grey" }}> {search}</span></h4>
            <div className="row" id="blog_masonry">
              {
                dataArray.length > 0
                  ?
                  dataArray.map((data, i) => {
                    return <>
                      <div className="col-lg-4 col-sm-6" onClick={() => navigate(`/post/${data.id}`)}>
                        <div className="blog_grid_item mb-30">
                          <div className="blog_content">
                            <div className="blog_img">
                              <Imgrendering
                                htmlString={data.content}
                              />
                            </div>
                            <NavLink to={`/post/${data.id}`}>
                              <h5 className="f_p f_size_20 f_500 t_color mb_20 len_text">{data.title}</h5>
                            </NavLink>
                            <div className='blog-description'>
                              <Htmlrendering content={data.content} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  }
                  )
                  :
                  <span style={{ color: 'rgb(50,73,214)', fontSize: '25px' }}>
                    No posts found
                  </span>
              }
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default SearchResults