import React from "react";
import "./Contact.css";
const Contact = () => {
  return (
    <>
      <section className="h4-contact s-padding bg-color" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="iconic-contact-info">
                <div className="contact-info-head">
                  <h2>Contact Information</h2>
                </div>
                <p>
                  Got questions, ideas, or just want to say hi? Shoot us an
                  email at contact@bayeslabs.co. We're all ears!
                </p>
                <ul className="f-contact-list">
                  <li>
                    <span>Address:</span> 309, Asian Sun City, Forest Dept
                    Colony,
                    <br /> Kondapur, Telangana 500084
                  </li>
                  <li>
                    <span>Call Us:</span> +91 9498866488
                  </li>
                  <li>
                    <span>Email Us:</span> contact@bayeslabs.co
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 offset-lg-1">
              <h3 className="contact-text">
                Let's dive deep into the AI and emerge as champions of
                innovation.
              </h3>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
