import React from "react";
import "./Hero.css";

const Hero = () => {
  return (
    <>
      <section className="home-4-banner main-banner main-slider" id="home">
        <div className="swiper-container home-4-slider w-100">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-8 offset-md-2">
                    <div className="banner-content">
                      <h2
                        class="wow fadeInUp text-center"
                        data-wow-delay="0.3s"
                      >
                        Welcome to Bayes Labs{" "}
                      </h2>
                      <p class="wow fadeInUp text-center" data-wow-delay="0.5s">
                        {" "}
                        Pioneering Open Source Research in AI{" "}
                      </p>

                      <p
                        data-wow-duration=".65s"
                        data-wow-delay=".1s"
                        class="herop wow fadeInUp"
                      >
                        Your go-to hub for all things artificial intelligence.
                        We're not just another community; we're a dynamic
                        playground where researchers, practitioners, and curious
                        minds collide to push the boundaries of AI.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div id="particles"></div> */}
      </section>
    </>
  );
};

export default Hero;
