import React, { useEffect, useState } from 'react';
import "./BlogsRoot.css";
import { Outlet, useNavigate } from 'react-router-dom';
import { collection, db, onSnapshot } from '../../firebaseConfig';
import Scrollto from '../../components/Scrollto/Scrollto';
import { Helmet } from 'react-helmet-async';

const BlogsRoot = () => {
    const [loader, setLoader] = useState(true);
    const blogsdata = [1, 2, 3, 4, 5, 6];
    const [dataArray, setDataArray] = useState([]);
    const [dataCategories, setDataCategories] = useState([]);
    const [searchvalue, setSearchvalue] = useState('');
    const [blognames, setBlogNames] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [noResult, setNoResult] = useState(false);
    const navigate = useNavigate();

    const breadstyle = {
        marginTop: '80px',
        position: "relative",
        zIndex: "1",
        padding: "0px 0px 0px",
        overflow: "hidden",
        background: "none"
    }


    // search field controllers
    const handleSearch = (e) => {
        setNoResult(false)
        console.log(e.target.value)
        const text = e.target.value;
        setSearchvalue(e.target.value)

        const matchingSuggestions = dataArray
            .filter(obj => obj.title.toLowerCase().includes(text.toLowerCase()))
            .map(obj => obj.title);

        setSuggestions(matchingSuggestions);
    }

    const findValue = () => {
        const foundObject = dataArray.find(obj => obj.title.toLowerCase().includes(searchvalue))
        if (foundObject) {
            navigate(`${searchvalue}`)
        } else {
            setNoResult(true)
            setTimeout(() => {
                setNoResult(false)
            }, 3000);

        }
    }


    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, 'bayeslabs-posts'), (querySnapshot) => {
            const newDataArray = [];
            querySnapshot.forEach((doc) => {
                newDataArray.push({ id: doc.id, ...doc.data() });
            });
            console.log(newDataArray)
            setDataArray(newDataArray);
            setTimeout(() => {
                setLoader(false)
            }, 2000);

        });


        const unsubscribe2 = onSnapshot(collection(db, 'bayeslabs-categories'), (querySnapshot) => {
            const newDataArray2 = [];
            querySnapshot.forEach((doc) => {
                newDataArray2.push({ id: doc.id, ...doc.data() });
            });
            console.log(newDataArray2)
            setDataCategories(newDataArray2);
            setTimeout(() => {
                setLoader(false)
            }, 2000);
        });

        return () => {
        }
    }, [])

    return (
        <>
            <Helmet>
                {/* <link rel="icon" type="image/png" href={fav} /> */}
                <title>Bayeslabs</title>
                <meta
                    name="description"
                    content={`Revolutionizing Clinical Trials: Unleashing the Hidden Power of AI in Advancing Biomarker-Driven Research
                    `}
                />
            </Helmet>
            {loader && <div className="loader"></div>}
            <Scrollto />
            <section style={breadstyle}>
                <div className="container">
                    <div className="card mb-2" style={{ background: 'none', border: 'none' }}>
                        <div className="card-body">
                            <div className='row'>
                                <div className='col-lg-8'>
                                    {/* {dataCategories.slice(0, 4).map((data) => <span className='chip' onClick={()=>navigate(`/${data.title}/blog`)}>{data.title}</span>)} */}
                                </div>
                                <div className='col-lg-4 col-md-'>
                                    <div class="input-group mb-3">
                                        <input type="text" class="form-control" placeholder="Search" onChange={(e) => handleSearch(e)} value={searchvalue} aria-label="search for blog" aria-describedby="button-addon2" />
                                        <button class="btn btn-outline-secondary" onClick={findValue} type="button" id="button-addon2" style={{ backgroundColor: 'blue', color: 'white' }}>Search</button>
                                    </div>
                                    {
                                        noResult &&
                                        <div class="alert alert-danger alert-white rounded">
                                            <div class="icon"><i class="fa fa-times-circle"></i></div>
                                            No Results found
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Outlet />
        </>
    )
}

export default BlogsRoot