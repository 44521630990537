import React, { useEffect, useState } from 'react'

const Detailrendering = ({ content,type }) => {

  useEffect(() => {

  }, [content]);
  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </>
  )
}

export default Detailrendering