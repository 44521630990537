import React, { useEffect, useState } from 'react'

const Htmlrendering = ({ content,type }) => {

  const [textContent, setTextContent] = useState('');

  useEffect(() => {
    const extractTextContent = (htmlString) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, 'text/html');
      const imgElements = doc.getElementsByTagName('img');

      // Remove img elements from the document
      while (imgElements[0]) {
        imgElements[0].parentNode.removeChild(imgElements[0]);
      }

      const extractedText = doc.body.innerText;
      setTextContent(extractedText);
    };

    extractTextContent(content);
  }, [content]);
  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: textContent }} />;
    </>
  )
}

export default Htmlrendering